<template>
  <v-dialog v-model="dialog" max-width="400px" @click:outside="resetForm()">
    <v-card>
      <v-card-title class="headline">Edit Videos Details</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-text-field
          label="Video 1"
          v-model="fields.video_1"
          outlined
        ></v-text-field>
        <v-text-field
          label="Video 2"
          v-model="fields.video_2"
          outlined
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          @click="saveForm()"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      fields: {
        video_1: "",
        video_2: "",
      },
    };
  },

  methods: {
    openForm: function (video1, video2) {
      this.fields.video_1 = video1;
      this.fields.video_2 = video2;
      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.fields = {
        video_1: "",
        video_2: "",
      };
    },

    saveForm: function () {
      const appId = this.$route.params.id;
      this.loading = true;

      this.$store
        .dispatch("grogreen/settings/saveSettings", {
          appId,
          fields: this.fields,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
