<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Settings</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-toolbar flat dense dark color="accent">
              <v-toolbar-title>Videos Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                small
                depressed
                @click="$refs.videosForm.openForm(video1, video2)"
              >
                <v-icon left>mdi-pencil</v-icon>Edit
              </v-btn>
            </v-toolbar>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Video 1</th>
                  <td>{{ video1 }}</td>
                </tr>
                <tr>
                  <th>Video 2</th>
                  <td>{{ video2 }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <VideosForm ref="videosForm" />
    </v-container>
  </div>
</template>

<script>
import VideosForm from "./components/VideosForm";

export default {
  components: {
    VideosForm,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Settings",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    video1() {
      return this.$store.state.grogreen.settings["video1"];
    },
    video2() {
      return this.$store.state.grogreen.settings["video2"];
    },
  },
};
</script>
